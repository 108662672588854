import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import tw from "twin.macro"
import SectionCard from "../Card/SectionCard"
import { useChat } from "../Chat/ChatContext"
import { integrationAutoCajaCards, integrationCards } from "../global/navigation"

export const IntegrationAutoCaja = ( ) => {

    const { targetCountry } = useChat()




    // Filtrar las tarjetas de integración según el país actual (si allowedCountries está definido)
    const filteredIntegrationCards = integrationAutoCajaCards.filter((card) => {
        
        // Verificar si allowedCountries está definido y si el código de país actual está en la lista permitida
        return !card.allowedCountries || card.allowedCountries.includes(targetCountry?.id);
    });



    const gridCols = 3

    return (
        <div css={tw`flex flex-col lg:grid   gap-4`}
            style={{
                gridTemplateColumns: `repeat(${gridCols}, minmax(0, 1fr))`
            }}>
            {filteredIntegrationCards.map((card, index) => {
                const to = card.to ? `/${targetCountry?.iso?.toLowerCase()}${card.to}` : "#"
                return (
                    <SectionCard key={index} haveOverflow havePadding={false}>
                        <Link
                            to={to}
                            css={tw`flex justify-center w-full p-4 overflow-hidden rounded-ultra transition-all duration-100 hover:bg-white hover:bg-opacity-50 items-center h-200px w-full`}
                        >
                            {card.to && <button
                                css={tw`top-0 right-0 m-auto p-2 mt-2 mr-2 absolute text-white bg-autogestiones-300 shadow flex rounded-full justify-center items-center`}
                            >
                                <i className="fa-up-right-from-square fa-solid"></i>
                            </button>}
                            <div css={tw`w-full`}>{card.imageSrc}</div>

                        </Link>
                    </SectionCard>
                )
            })}
        </div>
    );

}