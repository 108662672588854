import React, { ReactElement } from "react"
import tw from "twin.macro"

interface ChildrenProps {
	title: string
	children: any
	capitalColor?: boolean
	style?
}
const ColumnCard = ({
	style,
	title,
	capitalColor,
	children,
}: ChildrenProps): ReactElement => {
	const [firstWord, ...rest] = title.split(" ")

	return (
		<>
			<h2
				style={style}
				className="text-gradient-secondary"
				css={tw`text-xl lg:text-3xl font-medium text-center top-0 bottom-0 my-auto`}
			>
				{capitalColor ? (
					<>
						<span style={{ color: "#00b0f0" }}>{firstWord} </span>
						{rest.join(" ")}
					</>
				) : (
					[firstWord].concat(rest).join(" ")
				)}
			</h2>
			<div css={tw`text-center font-medium text-blackest lg:text-base text-sm`}>
				{children}
			</div>
		</>
	)
}

export default ColumnCard
